import { Injectable } from '@angular/core';
import { Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';

import { ActivationPartialState, ActivationActions } from './activation.state';
import { ActivationService } from '../activation.service';

@Injectable()
export class  ActivationEffects {

  @Effect()
  loadAllEntities$ = this.dataPersistence.fetch(ActivationActions.LoadAll.type, {
    run: (action: any, state) => this.apiService.getAll(action.payload)
                                      .pipe(map(response => ActivationActions.LoadedAll({ payload: response }))),
    onError: (action: any, error) => ActivationActions.ActionError(action + error)
  });

  @Effect()
  createEntity$ = this.dataPersistence.pessimisticUpdate(ActivationActions.Add.type, {
    run: (action: any, state) => this.apiService.add(action.payload)
                                      .pipe(map((response: any) => ActivationActions.Added({ payload: response }))),
    onError: (action: any, error) => ActivationActions.ActionError(action + error)
  });

  @Effect()
  updateEntity$ = this.dataPersistence.pessimisticUpdate(ActivationActions.Update.type, {
    run: (action: any, state) => this.apiService.update(action.payload)
                                      .pipe(map((response: any) => ActivationActions.Updated({ payload: response }))),
    onError: (action: any, error) => ActivationActions.ActionError(action + error)
  });

  @Effect()
  deleteEntity$ = this.dataPersistence.pessimisticUpdate(ActivationActions.Delete.type, {
    run: (action: any, state) => this.apiService.delete(action.payload)
                                      .pipe(map(response => ActivationActions.Deleted({ payload: response }))),
    onError: (action: any, error) => ActivationActions.ActionError(action + error)
  });

  constructor(
    private dataPersistence: DataPersistence<ActivationPartialState>,
    private apiService: ActivationService,
  ) {}
}
