// import { Client } from '@client/data-access';
import { BaseEntityState, BaseEntityActions, EmptyEntityState } from '@identic/api';
import { Activation, ACTIVATION_KEY_FIELD } from './activation.model';

export const ACTIVATION_FEATURE_KEY = 'Activation';

export type ActivationState = BaseEntityState<Activation>;
export const ActivationActions = new BaseEntityActions<Activation>(ACTIVATION_FEATURE_KEY, ACTIVATION_KEY_FIELD);

export interface ActivationPartialState {
  readonly [ACTIVATION_FEATURE_KEY]: ActivationState;
}

export const activationInitialState: ActivationState = new EmptyEntityState;
