import { Component } from '@angular/core';
@Component({
  selector: 'act-root',
  template: `<app-layout [title]="title" [shortTitle]="shortTitle" [siteLogoUrl]="siteLogoUrl"></app-layout>`
})
export class AppComponent {
  title = 'Product Activation';
  shortTitle = 'Activation';
  siteLogoUrl = 'assets/lucid-logo.png';
}
