// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// This file is replaced during build by using the `fileReplacements` array.
// `ng build -c staging-sms` replaces `environment.ts` with `sms/environment.staging.ts`.
// `ng build -c staging` replaces `environment.ts` with `environment.staging.ts`. *** Identic version
// The list of file replacements can be found in `angular.json`.
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --staging` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ANON_TEMPLATE_API_URL: 'https://servicesstaging.identic.com.au/api/Template/v1.0',  // For anon-templates
  VIRTUAL_DIR: '/',
  SSO_SERVER_URL: 'https://identitystaging.lucidcentral.org',
  API_BASE_URL: 'https://activationstaging.lucidcentral.org/api/v1.0',
  IDSVR4_API_SCOPE: 'ACTIVATION.API.Test',
  IDSVR4_API_CLIENT: 'ACTIVATION.UI.Test',
  menuClickClearsPageState: true,
};
