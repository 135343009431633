export const ACTIVATION_PATH ='activation';

export const SHOW_ACTIVATIONS_ACTION = {
  actionName: 'show-activations-event',
  title: 'Show Activations',
  icon: 'computer',
  colour: 'accent'
};

export const GENERATE_SERIAL_NUMBER_ACTION = {
  actionName: 'generate-serial-number-event',
  title: 'Generate a new serial number',
  icon: 'refresh',
  colour: 'accent'
}
