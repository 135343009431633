import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseFacade, EntityStateSelector } from '@identic/api';
import { ACTIVATION_KEY_FIELD, Activation } from './activation.model';
import { ACTIVATION_FEATURE_KEY, ActivationPartialState, ActivationState, ActivationActions } from './activation.state';

@Injectable()
export class ActivationFacade extends BaseFacade<Activation> {
  constructor(private store: Store<ActivationPartialState>) {
    super(store, ActivationActions, new EntityStateSelector<ActivationState>(ACTIVATION_FEATURE_KEY, ACTIVATION_KEY_FIELD));
  }
}
// console.log(`%cActivationFacade::ngOnInit`, 'background:yellow');
