import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthRoutes } from '@identic/auth';
import { NotFoundPageComponent } from '@identic/controls';
import { RedirectGuard } from '@identic/core';

import { appRoutes } from './app.routing';

const routes: Routes = [
  ...AuthRoutes,
  ...appRoutes,
  { path: '**', component: NotFoundPageComponent, canActivate: [RedirectGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    enableTracing: false, // <-- debugging purposes only
    // preloadingStrategy: SelectivePreloadingStrategyService,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
