import { Client } from '@client/data-access';
import { Licence } from '@licence/data-access';

export const ACTIVATION_KEY_FIELD = 'id';
export const ACTIVATION_DISPLAY_FIELD = 'activationNumber';

export interface Activation
{
  id?: string;
  registrationBlock?: string;
  hostname?: string;
  mac?: string;
  activationNumber?: string;
  dateActivated?: Date | string;
  unsuccessfulActivation?: boolean;
  unsuccessfulActivationReason?: string;
  deactivated?: boolean;
  deactivatedReason?: string;

  clientId?: string;
  client?: Client;
  licenceId?: string;
  licence?: Licence;
}
