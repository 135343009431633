import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BaseService } from '@identic/api';
import { ConfigService, safeValue } from '@identic/core';

import { Activation, ACTIVATION_DISPLAY_FIELD } from './+state/activation.model';

const defaultApi = 'activation';

@Injectable({providedIn: 'root'})
export class ActivationService extends BaseService<Activation> {

  constructor(http: HttpClient, config: ConfigService) {
    super(http, `${config.environment.API_BASE_URL}/${safeValue(config, 'environment.Api.Activation', defaultApi)}`, ACTIVATION_DISPLAY_FIELD);
  }

  public manualActivation  = (encodedXMLFile: string) => {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain');
    return this.post(encodedXMLFile, '/activate', { headers, responseType: 'text' });
  }
}
// console.log(`%cActivationService::ngOnInit`, 'background:yellow');