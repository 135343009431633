import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'act-home',
  template: `
  <mat-card class="container">
    <mat-card-content>
      <template-display [applicationId]="(routeData$ | async)!['applicationId']" templateName="HomePage"></template-display>
    </mat-card-content>
  </mat-card>
  `
})
export class HomeComponent {
  routeData$ = this.route.data;

  constructor(public route: ActivatedRoute, private router: Router) {}
}
// console.log(`%c HomeComponent::ngOnInit`, 'background:yellow');
