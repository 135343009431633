import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ActivationEffects } from './+state/activation.effects';
import { ActivationFacade } from './+state/activation.facade';
import { ActivationReducer } from './+state/activation.reducer';
import { ACTIVATION_FEATURE_KEY, activationInitialState as initialState } from './+state/activation.state';

// AOT compiler: Function calls are not supported in decorators
export const storeModuleForActivation = StoreModule.forFeature(ACTIVATION_FEATURE_KEY, ActivationReducer, { initialState });
export const effectsModuleForActivation = EffectsModule.forFeature([ActivationEffects]);

@NgModule({
  imports: [
    CommonModule,
    storeModuleForActivation,
    effectsModuleForActivation
  ],
  providers: [ActivationFacade]
})
export class ActivationDataAccessModule {}
