import { Routes } from '@angular/router';

import { MenuItems } from '@identic/layout';
import { AuthGuard } from '@identic/auth';
import { APPLICATION_IDENTIFIER_SELECTOR_PATH, ApplicationIdentifierSelectorComponent } from '@identic/application-identifier';

import { ACTIVATION_PATH } from '@activation/data-access';
import { HomeComponent } from './home.component';

// const DummyComponentForExternalUrl = HomeComponent;
// export const externalUrlResolver = { url: 'externalUrlRedirectResolver' };
// Page options are here and referenced twice below in Routes & menuItems
// E.G. appointments: { text: 'Appointments', icon: 'people', path: 'appointments', routerLink: 'appointments/list', roles: 'SMS.Admin&&(Unit.EDU||Unit.SLC)' },

const Page = {
  // login: { text: 'Login', icon: 'locked', path: 'login', routerLink: 'login', applicationId: 'eeg', loggedOutOnly: true },
  home: { text: 'Home', icon: 'home', path: 'home', routerLink: 'home', applicationId: 'Activation' },

  // emailValidation: { text: 'Email validation', icon: 'email', path: '/email/validate', routerLink: 'email/validate' },
  activations: { text: 'Activations', icon: 'people', path: ACTIVATION_PATH, routerLink: 'activation/list', roles: 'Administrators' },
  manualActivations: { text: 'Manual Activations', icon: 'people', path: 'activation/manual', routerLink: 'activation/manual', loggedInOnly: true },
  clients: { text: 'Clients', icon: 'people', path: 'client', routerLink: 'client/list', roles: 'Administrators' },
  clientMessages: { text: 'Client Messages', icon: 'people', path: 'client-message', routerLink: 'client-message/list', roles: 'Administrators' },
  countries: { text: 'Countries', icon: 'school', path: 'country', routerLink: 'country/list', roles: 'Administrators' },
  licences: { text: 'Licences', icon: 'event', path: 'licence', routerLink: 'licence/list', roles: 'Administrators' },
  clientLicences: { text: 'My Licences', icon: 'event', path: 'licence/client', routerLink: 'licence/client', loggedInOnly: true },
  products: { text: 'Products', icon: 'find_replace', path: 'product', routerLink: 'product/list', roles: 'Administrators' },

  applicationIdSelect: { text: 'Change Application', icon: 'swap_horizontal_circle', path: APPLICATION_IDENTIFIER_SELECTOR_PATH, routerLink: APPLICATION_IDENTIFIER_SELECTOR_PATH, roles: 'Administrators' },
  emailTemplates: { text: 'Email Templates', icon: 'email', path: 'email-template', routerLink: 'email-template/list', roles: 'Administrators||Email.Template.Editor' },
  pageTemplates: { text: 'Page Editor', icon: 'web', path: 'page-template', routerLink: 'page-template/list', roles: 'Administrators||Template.Editor' },
  logs: { text: 'Logs', icon: 'list', path: 'logs', routerLink: 'logs/list', roles: 'Administrators||LogToDb.Editor' },
};

export const appRoutes: Routes = [
  { path: Page.home.path, redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent, data: Page.home }, //, canActivate: [AuthGuard], data: { roles: 'admin' }  // rf: https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3

  { path: Page.applicationIdSelect.path, component: ApplicationIdentifierSelectorComponent, data: Page.applicationIdSelect }, //canActivate: [AuthGuard] },
  { path: Page.emailTemplates.path, loadChildren: () => import('@identic/email').then(m => m.EmailTemplateModule), data: Page.emailTemplates, /*canActivate: [AuthGuard]*/ },
  { path: Page.pageTemplates.path, loadChildren: () => import('@identic/templates').then(m => m.TemplateEditorModule), data: Page.pageTemplates, /*canActivate: [AuthGuard]*/},
  { path: Page.logs.path, loadChildren: () => import('@identic/log-to-db').then(m => m.LogToDbEditorModule), data: Page.logs, /*canActivate: [AuthGuard]*/},

  { path: Page.activations.path, loadChildren: () => import('@activation/feature').then(m => m.ActivationFeatureModule), data: Page.activations, canActivate: [AuthGuard] },
  { path: Page.manualActivations.path, loadChildren: () => import('@activation/feature').then(m => m.ActivationFeatureModule), data: Page.manualActivations, canActivate: [AuthGuard] },
  { path: Page.clients.path, loadChildren: () => import('@client/feature').then(m => m.ClientFeatureModule), data: Page.clients, canActivate: [AuthGuard] },
  { path: Page.clientMessages.path, loadChildren: () => import('@client-message/feature').then(m => m.ClientMessageFeatureModule), data: Page.clientMessages, canActivate: [AuthGuard] },
  { path: Page.countries.path, loadChildren: () => import('@country/feature').then(m => m.CountryFeatureModule), data: Page.countries, canActivate: [AuthGuard] },
  { path: Page.clientLicences.path, loadChildren: () => import('@licence/feature').then(m => m.LicenceFeatureModule), data: Page.clientLicences, canActivate: [AuthGuard] },
  { path: Page.licences.path, loadChildren: () => import('@licence/feature').then(m => m.LicenceFeatureModule), data: Page.licences, canActivate: [AuthGuard] },
  { path: Page.products.path, loadChildren: () => import('@product/feature').then(m => m.ProductFeatureModule), data: Page.products, canActivate: [AuthGuard] },
];

export const menuItems: MenuItems = [
  // User profile option
  {
    text: '',             // userDisplayName
    // path?: string;       // Profile path
    // routerLink?: string; // Profile path
    icon: 'person',
    hint: 'User',
    isProfile: true,
  },

  // Page.login,
  Page.home,

  // Client options
  Page.clientLicences,

  // Admin options
  Page.activations,
  Page.manualActivations,
  Page.clients,
  Page.clientMessages,
  Page.countries,
  Page.licences,
  Page.products,

  Page.logs,
  Page.pageTemplates,
  Page.emailTemplates,
];
